import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { createStore, applyMiddleware, compose } from 'redux'
import rootReducer from './store/reducers/rootReducer'
import { Provider,useSelector } from 'react-redux'
import { reduxFirestore, getFirestore } from 'redux-firestore'
import {getFirebase, isLoaded } from 'react-redux-firebase'
import fbConfig from './store/api/fbConfig'
import thunk from 'redux-thunk'
import { ReactReduxFirebaseProvider } from 'react-redux-firebase'
import { createFirestoreInstance } from 'redux-firestore'
import firebase from 'firebase/compat/app'
import Login from './views/auth/Login';

import { getAuth } from "firebase/auth";


import Loader from "./components/other/Loader"
import Error from "./views/PageError"

import {GetProfile} from "./store/actions/profileActions"


const middleware = applyMiddleware(
  thunk.withExtraArgument({
      getFirebase,
      getFirestore
  })
);

const store = createStore(rootReducer,
  compose(
      middleware,
      //reactReduxFirebase(fbConfig,{attachAuthIsReady:true}),
      reduxFirestore(fbConfig)
  )
);



if(!store.getState().profile)
{
  GetProfile()(store.dispatch,store.getState);
}


function AuthIsLoaded({ children }) 
{
  const auth = useSelector(state => state.firebase.auth)
  const profile = useSelector(state => state.profile)


  if (!isLoaded(auth)) 
  {
    console.log("isLoaded(auth)")
    return (<Loader/>);
  }

    if (!auth || !auth.uid)
    {
      return( <Login/>)
    } 

    
    if(!profile || profile.error)
    {  
      console.log("!profile || !profile.error")
      return (<Error/>);
    }


    if(profile && !profile.isLoaded)
    {  
      console.log("profile && !profile.loggedin")
      GetProfile();

      return (<Loader/>);
    }


    
  return children
}



ReactDOM.render(
  <Provider store={store}>
      <ReactReduxFirebaseProvider
          firebase={firebase}
          config={fbConfig}
          dispatch={store.dispatch}
          createFirestoreInstance={createFirestoreInstance}>

          <AuthIsLoaded>
              <App />
          </AuthIsLoaded>

      </ReactReduxFirebaseProvider>
  </Provider>, document.getElementById('root'));


