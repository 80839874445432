
import React, {useEffect ,useState  } from 'react'
import { NavLink,Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { firestoreConnect } from  'react-redux-firebase'


import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';

import Page404 from './Page404';
import Loader from "../components/other/Loader"

import {baseAPIURL,downloadAPIURL} from "../store/api/Access"

import  axios  from 'axios'

import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';

import AddIcon from '@mui/icons-material/Add';
import BackIcon  from '@mui/icons-material/ArrowBackIos';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import Box from '@mui/material/Box';





const View = () => {
    

    
    const [limit, setLimit] = useState(1000);
    const [list, setList] = useState(null);
    const [{loading,loadingMore,error}, setStatus] = useState({loading:true});
  
    //const navigate = useNavigate();
    

    const HandleLoadMore = () => {

      const NewLimit = limit+1000;
      setLimit(NewLimit)
      setStatus({loadingMore:true})
      firebase.auth().currentUser.getIdToken().then(function(idToken) {


          let data = JSON.stringify({
            "limit": NewLimit
          });

          let config = {
            method: 'post',
            //maxBodyLength: Infinity,
            url: baseAPIURL+'contacts/list',
            headers: { 
              'Authorization': idToken, 
              //'Content-Type': 'application/json'
            },
            data : data
          };
          
          axios.request(config).then(Result => {
                                  
              console.log(Result)
              if(Result.data.error)
              {
                  setStatus({error:true});
              }
              else if(Result.data.list)
              {
                  setList(Result.data.list)
                  setStatus({});
              }
              else
              {
                  setList([])
                  setStatus({error:true});
              }
            
        }).catch(err => {
              console.error("handleSubmit")
              console.error(err)
              setStatus({error:true});
        });
      });



    }



      const LoadData = () => {
          setStatus({loading:true});
          //setList(null)
          console.log("LoadData")
  
          console.log(firebase.auth().currentUser)
          firebase.auth().currentUser.getIdToken().then(function(idToken) {

            let data = JSON.stringify({
              "limit": limit
            });

            let config = {
              method: 'post',
              maxBodyLength: Infinity,
              url: baseAPIURL+'contacts/list',
              headers: { 
                'Authorization': idToken, 
                'Content-Type': 'application/json'
              },
              data : data
            };
            
            axios.request(config).then(Result => {
                                    
                console.log(Result)
                if(Result.data.error)
                {
                    setStatus({error:true});
                }
                else if(Result.data.list)
                {
                    setList(Result.data.list)
                    setStatus({});
                }
                else
                {
                    setList([])
                    setStatus({error:true});
                }
              
          }).catch(err => {
                console.error("handleSubmit")
                console.error(err)
                setStatus({error:true});
          });
        });
          
      };
  
    useEffect(() => {
      LoadData();
    }, []);

    if(error)
      return(<Page404/>)

    if(loading)
      return(<Loader/>)


    return (
        <Box>
          <Toolbar className='BeadcrumToolBar'>
            
                        <IconButton  component={NavLink} to="/" >
                            <BackIcon/>
                        </IconButton>

                        <Typography   sx={{ flexGrow: 1 }} component={NavLink} to="/" className='Title' variant="h6" >
                            Stats
                        </Typography>
                    

          </Toolbar>
    <TableContainer >
    <Table sx={{ minWidth: 650 }} aria-label="simple table">
      <TableHead>
        <TableRow>
          <TableCell align="right">Name</TableCell>
          <TableCell align="right">Email</TableCell>
          <TableCell align="right">Number</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {list && list.map((row) => (
          <TableRow
            hover
            key={row.name}
            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            component={NavLink}
            to={"/contact/"+row.id}
          >
            <TableCell align="right">{row.number}</TableCell>
            <TableCell align="right">{row.Email}</TableCell>
            <TableCell align="right">{row.number}</TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  </TableContainer>
        </Box>
    )
}
export default View
