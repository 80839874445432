import React,{useEffect} from 'react'
import { NavLink,Link } from 'react-router-dom'

import { useNavigate } from 'react-router-dom';

import Box from '@mui/material/Box';
import { Container } from '@mui/material';

import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Alert from '@mui/material/Alert';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';


import Dialog from '@mui/material/Dialog';

import AddIcon from '@mui/icons-material/Add';
import BackIcon  from '@mui/icons-material/ArrowBackIos';

import  axios  from 'axios'
import {baseAPIURL} from "../../store/api/Access"
import firebase from 'firebase/compat/app'

import Page404 from '../Page404';
import Loader from "../../components/other/Loader"
import {  useParams } from 'react-router-dom';
import ClearIcon from '@mui/icons-material/Clear';

const View = () => {
  const [value, setValue] = React.useState(null);
  const [openDelete, setOpenDelete] = React.useState(false);
  const [statsArray, setStatsArray] = React.useState([]);
  const [{loading,saving,saved,error}, setStatus] = React.useState({loading:true});
    const navigate = useNavigate();
    const Params = useParams();


    const handleChange = (e) => {
        setValue({... value, [e.target.name]:e.target.value})

    }

    const handleSubmit = () => {
        if(!("name" in value) || value.name == "")
        {
            alert("Name is required")
            return
        }


        
      setStatus({saving:true})

      firebase.auth().currentUser.getIdToken().then(function(idToken) {

        var DataRx = {
            "name":value.name,
            "id": "id" in Params && Params.id ? Params.id : ""
        }
        
        if(statsArray.length>0)
        {
            DataRx["stats"] = []      
            statsArray.forEach(element => {
                DataRx["stats"].push(element)
            });
        }

        if("description" in value)
            DataRx["description"] = value.description
        

        let data = JSON.stringify(DataRx);

        let config = {
          method: 'post',
          maxBodyLength: Infinity,
          url: baseAPIURL+'activities/update',
          headers: { 
            'Authorization': idToken, 
            'Content-Type': 'application/json'
          },
          data : data
        };
        
        axios.request(config).then(Result => {
                                
            console.log(Result)
            if(Result.data.error)
            {
              setStatus({error:true});
            }
            else if(Result.data.success)
            {
              setStatus({saved:true});
            }
            else
            {
              setStatus({error:true});
            }
          
      }).catch(err => {
            console.error("handleSubmit")
            console.error(err)
            setStatus({error:true});
      });
    });

    }




    const handleSubmitDelete = () => {
            
      setStatus({saving:true})
      setOpenDelete(false)
      firebase.auth().currentUser.getIdToken().then(function(idToken) {
  
        var DataRx = {
            "id": "id" in Params && Params.id ? Params.id : ""
        }
  
        let data = JSON.stringify(DataRx);
  
        let config = {
          method: 'post',
          maxBodyLength: Infinity,
          url: baseAPIURL+'activities/remove',
          headers: { 
            'Authorization': idToken, 
            'Content-Type': 'application/json'
          },
          data : data
        };
        
        axios.request(config).then(Result => {
                                
            console.log(Result)
            if(Result.data.error)
            {
                setStatus({error:true});
            }
            else if(Result.data.success)
            {
                setStatus({saved:true});
                navigate("/activities")
  
  
            }
            else
            {
                setStatus({error:true});
            }
          
      }).catch(err => {
            console.error("handleSubmit")
            console.error(err)
            setStatus({error:true});
      });
    });
  
    }
  
    

    const LoadData = () => {
        setStatus({loading:true});
        //setList(null)
        console.log("LoadData")

        console.log(firebase.auth().currentUser)
        firebase.auth().currentUser.getIdToken().then(function(idToken) {

          let data = JSON.stringify({
            "id": "id" in Params && Params.id ? Params.id : null
          });

          let config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: baseAPIURL+'activities/get',
            headers: { 
              'Authorization': idToken, 
              'Content-Type': 'application/json'
            },
            data : data
          };
          
          axios.request(config).then(Result => {
                                  
                console.log(Result)
                if(Result.data.error)
                {
                    setStatus({error:true});
                }
                else if(Result.data)
                {
                    setValue(Result.data)
                    if("stats" in Result.data)
                    {
                      setStatsArray(Result.data.stats)
                    }
                    setStatus({});
                }
                else
                {
                    setValue(null)
                    setStatus({error:true});
                }
            
        }).catch(err => {
              console.error("handleSubmit")
              console.error(err)
              setStatus({error:true});
        });
      });
        
    };

  useEffect(() => {
    LoadData();
  }, []);



  
    
  const handleRemoveStat = (index) =>{
        
    var tempArray = [...statsArray]
    tempArray.splice(index, 1);
    setStatsArray(tempArray)
}
const handleAddStat = () =>{
    setStatsArray([...statsArray, ""])
}

const handleChangeStat = (index, statValue) =>{
    var tempArray = [...statsArray]
    tempArray[index] = statValue;
    setStatsArray(tempArray)

}



  if(error && !value)
    return(<Page404/>)

  if(loading)
    return(<Loader/>)


    
    return (
        <Box>

            <Toolbar className='BeadcrumToolBar'>
                <IconButton  component={NavLink} to="/activities" >
                    <BackIcon/>
                </IconButton>

                <Typography   sx={{ flexGrow: 1 }}  >Activitie</Typography>

            </Toolbar>


            <Container>
            
            {error ? <Alert severity="error">There was an error, try again later.</Alert>:null}
            {saved ? <Alert severity="success">Saved</Alert>:null}

                
            <TextField onChange={handleChange} value={value.name} sx={{mt:3}} label="Name" name="name" variant="outlined" fullWidth />
            <TextField onChange={handleChange} value={value.description} sx={{mt:3}} label="Description" name="description"  variant="outlined" fullWidth />


            <Box>
              <h3>Status</h3>
              <p></p>
              {statsArray.map((row,index) => (
                <Box sx={{display:"flex"}}>
                    <TextField onChange={(e)=>handleChangeStat(index,e.target.value)} value={row} sx={{mt:1, flexGrow:1}}  variant="outlined" />
                    
                    <Button onClick={(e)=>handleRemoveStat(index)} sx={{mt:1}}><ClearIcon/></Button>
                </Box>
              ))}

              <Button onClick={handleAddStat} variant="contained" sx={{mt:1}}>+</Button>

            </Box>

            
            <Button onClick={handleSubmit} variant="contained" sx={{mt:3}}>Save</Button>
            <br/>
            <br/>


            <Button onClick={()=>setOpenDelete(true)} variant="contained" sx={{mt:3}}>Delete</Button>

            </Container>


            <Dialog open={saving}>
            <Box sx={{p:3}}>
                Saving
              </Box>
            </Dialog>
            
            <Dialog  open={openDelete}>
              <DialogTitle>Delete</DialogTitle>
              <DialogContent>
                <DialogContentText>
                  Are you sure you want to delete?
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={()=>setOpenDelete(false)}>Cancel</Button>
                <Button onClick={handleSubmitDelete}>Delete</Button>
              </DialogActions>
            </Dialog>

        </Box>

    )
}
export default View
