import React,{useEffect} from 'react'
import { NavLink,Link } from 'react-router-dom'

import { useNavigate } from 'react-router-dom';

import Box from '@mui/material/Box';
import { Container } from '@mui/material';

import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Alert from '@mui/material/Alert';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox  from '@mui/material/Checkbox';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';

import Dialog from '@mui/material/Dialog';

import AddIcon from '@mui/icons-material/Add';
import BackIcon  from '@mui/icons-material/ArrowBackIos';

import  axios  from 'axios'
import {baseAPIURL} from "../store/api/Access"
import firebase from 'firebase/compat/app'

import Page404 from './Page404';
import Loader from "../components/other/Loader"
import {  useParams } from 'react-router-dom';


const View = () => {
  
    
    const [loadingURL, setLoadingURL] = React.useState(false);
    const [includeStats, setIncludeStats] = React.useState(1);

    const [daysAgo, setDaysAgo] = React.useState(30);
    const [selected, setSelected] = React.useState([]);
    const [listActivities, setListActivities] = React.useState(null);
  

  const [{loading,saved,saving,error}, setStatus] = React.useState({loading:true});
    const navigate = useNavigate();
    const Params = useParams();



    const handleSelectChange = (value,uuid) => {
        
        var selectedTemp = [...selected]
        if(!value)
        {
            const index = selectedTemp.indexOf(uuid);
            if (index > -1) 
            { 
                selectedTemp.splice(index, 1); 
            }
        }
        else
        {
            selectedTemp.push(uuid)
        }
        setSelected(selectedTemp)
        console.log(selectedTemp)
    }

    const handleSelectAll = () => {
        var includeStatsTemp = []
        listActivities.forEach(element => {
            includeStatsTemp.push(element.id)
        });

        setSelected(includeStatsTemp); 
    }


    const handleSelectNone = () => {
        setSelected([])
    }

    
    const handleSubmit = () => {
        setLoadingURL(true)
        firebase.auth().currentUser.getIdToken().then(function(idToken) {

            var url = "https://pdf.advlog.net?days="+daysAgo+"&Stats="+includeStats+"&activities="

            selected.forEach((element,index) => {
                if(index >0)
                    url +=","

                    url +=element
            });

            url +="&token="+idToken
            

            window.open(url, '_blank').focus();
            
        setLoadingURL(false)
        })
    }




    

    const LoadData = () => {
        setStatus({loading:true});
        //setList(null)
        console.log("LoadData")

        console.log(firebase.auth().currentUser)
        firebase.auth().currentUser.getIdToken().then(function(idToken) {

          let data = JSON.stringify({
            "limit": 100000
          });

          let config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: baseAPIURL+'activities/list',
            headers: { 
              'Authorization': idToken, 
              'Content-Type': 'application/json'
            },
            data : data
          };
          
          axios.request(config).then(Result => {
                                  
              console.log(Result)
              if(Result.data.error)
              {
                  setStatus({error:true});
              }
              else if(Result.data.list)
              {
                  setListActivities(Result.data.list)
                  setStatus({});
              }
              else
              {
                  setListActivities([])
                  setStatus({error:true});
              }
            
        }).catch(err => {
              console.error("handleSubmit")
              console.error(err)
              setStatus({error:true});
        });
      });
        
    };

  useEffect(() => {
    LoadData();
  }, []);

  if(error)
    return(<Page404/>)

  if(loading)
    return(<Loader/>)


    
    return (
        <Box>

            <Toolbar className='BeadcrumToolBar'>
                <IconButton  component={NavLink} to="/" >
                    <BackIcon/>
                </IconButton>

                <Typography   sx={{ flexGrow: 1 }}  >
                    Make PDF
                </Typography>

            </Toolbar>


            <Container>
                
                <FormControlLabel control={<Checkbox checked={includeStats} onChange={(e)=>setIncludeStats(e.target.checked)} />} label="Include stats" />
                
                <TextField onChange={(e)=>setDaysAgo(e.target.value)} value={daysAgo} sx={{mt:3}} label="Last Days ago" name="email"  variant="outlined" fullWidth />

                <Box sx={{mt:3}}>
                    <Button onClick={handleSelectAll} sx={{p:0, mr:1,fontSize:"12px"}}>All</Button>
                    <Button onClick={handleSelectNone} sx={{p:0, mr:1,fontSize:"12px"}}>None</Button>
                    <hr/>
                    <Box>
                        {listActivities && listActivities.map((row) => (
                            <Box>
                                <FormControlLabel control={<Checkbox  checked={selected.includes(row.id)} onChange={(e)=>handleSelectChange(e.target.checked,row.id)} />} label={row.name} />
                            </Box>
                        ))}
                    </Box>
                </Box>



                <Button onClick={handleSubmit} variant="contained" sx={{mt:3}}>add</Button>
            
            </Container>


            <Dialog  open={loadingURL}>
              <Box sx={{p:3}}>
                loading
              </Box>
            </Dialog>


        </Box>

    )
}
export default View
