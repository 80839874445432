import firebase from 'firebase/compat/app'
import 'firebase/compat/firestore'
import 'firebase/compat/auth'
import 'firebase/compat/analytics'
import 'firebase/compat/storage'
//import  Firebaseui  from 'firebaseui'
import { getAuth, connectAuthEmulator } from "firebase/auth";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyBZB9JOgMGsVsGqJrkcs6lppS5wVo8Bw-4",
  authDomain: "advlog-net.firebaseapp.com",
  projectId: "advlog-net",
  storageBucket: "advlog-net.appspot.com",
  messagingSenderId: "68227920113",
  appId: "1:68227920113:web:a5a6868e5c906ef5597950",
  measurementId: "G-KEBKGNK4BP"
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);
firebase.analytics();
firebase.storage();



//const db = firebase.firestore();
const auth = firebase.auth;

/*
// eslint-disable-next-line no-restricted-globals
if (location.hostname === 'localhost') {
  const auth2 = getAuth();
  connectAuthEmulator(auth2,'http://localhost:9099/');//, { disableWarnings: true });
}*/

export default firebase;
export { /*db,*/ auth };