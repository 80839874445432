import React from 'react'

import Box from '@mui/material/Box';

const contact = () => {
    
    return (
        <Box>
            <h3>Could not Load.</h3>
            <p>Please try again later.</p>
        </Box>
    )
}
export default contact
