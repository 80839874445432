import React, {Component } from 'react'
import HikingIcon from '@mui/icons-material/Hiking';
import DownhillSkiingIcon from '@mui/icons-material/DownhillSkiing';
import DirectionsBikeIcon from '@mui/icons-material/DirectionsBike';
import SnowshoeingIcon from '@mui/icons-material/Snowshoeing';
import SportsMartialArtsIcon from '@mui/icons-material/SportsMartialArts';
import KitesurfingIcon from '@mui/icons-material/Kitesurfing';
import PoolIcon from '@mui/icons-material/Pool';
import SnowboardingIcon from '@mui/icons-material/Snowboarding';
import SurfingIcon from '@mui/icons-material/Surfing';

const iconList = [HikingIcon,DownhillSkiingIcon,DirectionsBikeIcon,SnowshoeingIcon,SportsMartialArtsIcon,KitesurfingIcon,PoolIcon,SnowboardingIcon,SurfingIcon]
const Loader = (props) => {

  const shuffled = ([...iconList].sort(() => 0.5 - Math.random())).slice(0,3);

    return(
        <div class={props && props.color == "green" ? "loading-container-green" :  "loading-container"}>
          <div class="loading-dots">
            {shuffled &&   shuffled.map((Row) => <span class="dot"><Row/></span>)}
          </div>
          <div class="loading-text">Loading</div>
        </div>
    )

}

export default (Loader);