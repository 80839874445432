import React from 'react'
import { NavLink,Link } from 'react-router-dom'

import { useNavigate } from 'react-router-dom';

import Box from '@mui/material/Box';
import { Container } from '@mui/material';

import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Alert from '@mui/material/Alert';

import Dialog from '@mui/material/Dialog';

import AddIcon from '@mui/icons-material/Add';
import BackIcon  from '@mui/icons-material/ArrowBackIos';

import  axios  from 'axios'
import {baseAPIURL} from "../../store/api/Access"
import firebase from 'firebase/compat/app'


const View = () => {
    const [value, setValue] = React.useState({});
    const [{loading,saving,error}, setStatus] = React.useState({loading:true});
    const navigate = useNavigate();



    const handleChange = (e) => {
        setValue({... value, [e.target.name]:e.target.value})

    }

    const handleSubmit = () => {
        if(!("Name" in value) || value.Name == "")
        {
            alert("Name is required")
            return
        }


        
      setStatus({saving:true})

      firebase.auth().currentUser.getIdToken().then(function(idToken) {

        var DataRx = {
            "name":value.Name
        }

        if(value.Email)
            DataRx["email"] = value.Email

        if(value.PhoneNumber)
            DataRx["number"] = value.PhoneNumber
        


        let data = JSON.stringify(DataRx);

        let config = {
          method: 'post',
          maxBodyLength: Infinity,
          url: baseAPIURL+'contacts/add',
          headers: { 
            'Authorization': idToken, 
            'Content-Type': 'application/json'
          },
          data : data
        };
        
        axios.request(config).then(Result => {
                                
            console.log(Result)
            if(Result.data.error)
            {
                setStatus({error:true});
            }
            else if(Result.data.success)
            {
                setStatus({});

                if(Result.data.id)
                {
                  navigate("/contacts/"+Result.data.id)
                }
            }
            else
            {
                setStatus({error:true});
            }
          
      }).catch(err => {
            console.error("handleSubmit")
            console.error(err)
            setStatus({error:true});
      });
    });

    }
    
    return (
        <Box>

            <Toolbar className='BeadcrumToolBar'>
                <IconButton  component={NavLink} to="/contacts" >
                    <BackIcon/>
                </IconButton>

                <Typography   sx={{ flexGrow: 1 }}  >Contacts add</Typography>

            </Toolbar>


            <Container>
            
            
            {error ? <Alert severity="error">There was an error, try again later.</Alert>:null}
            
                
            <TextField onChange={handleChange} value={value.Name} sx={{mt:3}} label="Name" name="Name" variant="outlined" fullWidth />
            <TextField onChange={handleChange} value={value.Email} sx={{mt:3}} label="Email" name="Email"  variant="outlined" fullWidth />
            <TextField onChange={handleChange} value={value.PhoneNumber} sx={{mt:3}} label="Phone Number" name="PhoneNumber"  variant="outlined" fullWidth />

            <Button onClick={handleSubmit} variant="contained" sx={{mt:3}}>add</Button>

            </Container>


            <Dialog open={saving}>
            <Box sx={{p:3}}>
                Saving
              </Box>
            </Dialog>

        </Box>

    )
}
export default View
