import React from 'react';

import { BrowserRouter, Route, Routes } from 'react-router-dom'

import Header from './components/header/Header'
import Footer from './components/footer/Footer'
import Page404 from './views/Page404' 
import Logout from './views/auth/Logout'

import Settings from './views/Settings'
import Stats from './views/Stats'
import Files from './views/Files'
import PDF from './views/PDF'

import LogList from './views/Log/List'
import LogAdd from './views/Log/Add'
import LogDetails from './views/Log/Details'

import ContactsList from './views/Contacts/List'
import ContactsAdd from './views/Contacts/Add'
import ContactsDetails from './views/Contacts/Details'

import ActivitiesList from './views/Activities/List'
import ActivitiesAdd from './views/Activities/Add'
import ActivitiesDetails from './views/Activities/Details'


function App() {
  return (
    <BrowserRouter>
      <div className="App">
          <Header />

          <div className="MainView">
            <Routes >
              <Route path='/' element={<LogList/>} />
              <Route path='/logout' element={<Logout/>} />

              <Route path='/add' element={<LogAdd/>} />
              <Route path='/pdf' element={<PDF/>} />
              <Route path='/files' element={<Files/>} />
              <Route path='/stats' element={<Stats/>} />
              <Route path='/settings' element={<Settings/>} />

              <Route path='/log' element={<LogList/>} />
              <Route path='/log/add' element={<LogAdd/>} />
              <Route path='/log/:id' element={<LogDetails/>} />

              <Route path='/contacts' element={<ContactsList/>} />
              <Route path='/contacts/add' element={<ContactsAdd/>} />
              <Route path='/contacts/:id' element={<ContactsDetails/>} />

              <Route path='/activities' element={<ActivitiesList/>} />
              <Route path='/activities/add' element={<ActivitiesAdd/>} />
              <Route path='/activities/:id' element={<ActivitiesDetails/>} />

              
              <Route path='*'  element={<Page404/>}  status={404}/>

            </Routes>
          </div>


          <Footer />
      </div>
    </BrowserRouter>
  );
}

export default App;
