import React,{useEffect} from 'react'
import { NavLink,Link } from 'react-router-dom'

import { useNavigate } from 'react-router-dom';

import Box from '@mui/material/Box';
import { Container } from '@mui/material';

import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Alert from '@mui/material/Alert';

import Dialog from '@mui/material/Dialog';

import AddIcon from '@mui/icons-material/Add';
import BackIcon  from '@mui/icons-material/ArrowBackIos';
import DeleteIcon  from '@mui/icons-material/Delete';

import  axios  from 'axios'
import {baseAPIURL} from "../store/api/Access"
import firebase from 'firebase/compat/app'

import Page404 from './Page404';
import Loader from "../components/other/Loader"
import {  useParams } from 'react-router-dom';


const View = () => {
  const [value, setValue] = React.useState([]);
  const [{loading,success,saving,error}, setStatus] = React.useState({loading:true});
    const navigate = useNavigate();
    const Params = useParams();



    const handleChange = (e) => {
      if(success )
      setStatus({});
        setValue({... value, [e.target.name]:e.target.value})

    }

    const handleSubmit = () => {

      let findDuplicates = arr => arr.filter((item, index) => arr.indexOf(item) !== index)

      if( value.meta.indexOf("")>=0 || value.meta.indexOf(" ")>=0)
      {
        alert("Additional Fields cannot be blank.")
        return
      }
      if(findDuplicates(value.meta).length>0)
      {
        alert("Additional Fields has Duplicates.")
        return
      }
        
      setStatus({saving:true})

      firebase.auth().currentUser.getIdToken().then(function(idToken) {

        var DataRx = {
            "name":value.name,
            "id": "id" in Params && Params.id ? Params.id : ""

        }

        if("email" in value)
            DataRx["email"] = value.email

        if("number" in value)
            DataRx["number"] = value.number
            
        if("meta" in value)
            DataRx["meta"] = value.meta
        

        let data = JSON.stringify(DataRx);

        let config = {
          method: 'post',
          maxBodyLength: Infinity,
          url: baseAPIURL+'profile/update',
          headers: { 
            'Authorization': idToken, 
            'Content-Type': 'application/json'
          },
          data : data
        };
        
        axios.request(config).then(Result => {
                                
            console.log(Result)
            if(Result.data.error)
            {
                setStatus({error:true});
            }
            else if(Result.data.success)
            {
                setStatus({success:true});
            }
            else
            {
                setStatus({error:true});
            }
          
      }).catch(err => {
            console.error("handleSubmit")
            console.error(err)
            setStatus({error:true});
      });
    });

    }


    
    const handleRemoveMeta = (index) => {
      if(success )
      setStatus({});
      if(index==-1 || !value || !value.meta)
          return

          var listTemp = {...value}
          listTemp.meta.splice(index, 1); 
      setValue(listTemp)
  }


  const handleChangeMeta = (dateValue,index) => {
    if(success )
    setStatus({});

    if(index==-1 || !value || !value.meta)
    return

    var listTemp = {...value}
    listTemp.meta[index] = dateValue 
    setValue(listTemp)
}

  const handleAddMeta = () => {
    if(success )
    setStatus({});
    if(!value.meta)
    {
      setValue({...value, meta:[""]})
      return
    }

    var listTemp = {...value}
    listTemp.meta.push("");
    setValue(listTemp)
  }



    

    const LoadData = () => {
        setStatus({loading:true});
        //setList(null)
        console.log("LoadData")

        console.log(firebase.auth().currentUser)
        firebase.auth().currentUser.getIdToken().then(function(idToken) {

          let config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: baseAPIURL+'profile',
            headers: { 
              'Authorization': idToken, 
              'Content-Type': 'application/json'
            },
            data : "{}"
          };
          
          axios.request(config).then(Result => {
                                  
                console.log(Result)
                if(Result.data.error)
                {
                    setStatus({error:true});
                }
                else if(Result.data)
                {
                    setValue(Result.data)
                    setStatus({});
                }
                else
                {
                    setValue(null)
                    setStatus({error:true});
                }
            
        }).catch(err => {
              console.error("handleSubmit")
              console.error(err)
              setStatus({error:true});
        });
      });
        
    };

  useEffect(() => {
    LoadData();
  }, []);

  if(error && !value)
    return(<Page404/>)

  if(loading)
    return(<Loader/>)


    
    return (
        <Box>

            <Toolbar className='BeadcrumToolBar'>
                <IconButton  component={NavLink} to="/" >
                    <BackIcon/>
                </IconButton>

                <Typography   sx={{ flexGrow: 1 }}  >
                    Settings
                </Typography>

            </Toolbar>


            <Container>
            

            {error ? <Alert severity="error">There was an error, try again later.</Alert>:null}
            {success ? <Alert severity="success">Saved</Alert>:null}
            
            
            <TextField onChange={handleChange} value={value.name} sx={{mt:3}} label="Name" name="name" variant="outlined" fullWidth />
            <TextField onChange={handleChange} value={value.email} sx={{mt:3}} label="Email" name="email"  variant="outlined" fullWidth />
            <TextField onChange={handleChange} value={value.number} sx={{mt:3}} label="Phone Number" name="number"  variant="outlined" fullWidth />




            <Box sx={{p:2,mt:3, border:"2px #002e01 solid !important"}}>

<h2>Additional Fields</h2>
<hr/>
            {value.meta && value.meta.map((row,index) => {

              return (
                    <Box sx={{display:"flex",mt:2}}>
                    <TextField onChange={(e)=>handleChangeMeta(e.target.value,index)} value={row} sx={{flexGrow:1}} variant="outlined" fullWidth />

                    <IconButton onClick={()=>handleRemoveMeta(index)}><DeleteIcon/></IconButton>
                    </Box>

            )})}

            <Button onClick={()=>handleAddMeta()} variant="contained" sx={{mt:3}}>Add</Button>



</Box>


                <Box sx={{display:"flex"}}>
                <Button onClick={handleSubmit} variant="contained" sx={{mt:3}}>Save</Button>
                <Box sx={{flexGrow:1}}/>
                <Button component={NavLink} to="/logout" color="error" variant="contained" sx={{mt:3}}>Logout</Button>
            </Box>

            </Container>


            <Dialog open={saving}>
                Saving
            </Dialog>
                <br/>
                <br/>
                <br/>
                <br/>
        </Box>

    )
}
export default View
