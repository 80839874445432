import React, { useState,useRef  } from 'react';
import firebase from "firebase/compat/app";
import "firebase/compat/storage";
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';

import  axios  from 'axios'
import {baseAPIURL} from "../../store/api/Access"


function FileUpload({sx, onUpload,onRemove, value}) {
    const [files, setFiles] = useState({});
    const hiddenFileInput = useRef(null);

    const handleFileChange = (e) => {
        

        console.log(e)
        console.log("e.target.files")
        console.log(e.target.files)

        var fileTemp = {...files}

        Array.from(e.target.files).forEach(file => {
           
        if (!file) return;

        if(file.size > (1024 * 1024) * 10)
        {
            fileTemp[file.name]={error:"Too Large"}
            setFiles(fileTemp);

            return
        }


        fileTemp[file.name]={uploading:true}
        setFiles(fileTemp);

        

      firebase.auth().currentUser.getIdToken().then(function(idToken) {

        var DataRx = {
            "name":file.name,
            "fileName": file.name
        }
        
        let data = JSON.stringify(DataRx);

        let config = {
          method: 'post',
          maxBodyLength: Infinity,
          url: baseAPIURL+'files/add',
          headers: { 
            'Authorization': idToken, 
            'Content-Type': 'application/json'
          },
          data : data
        };
        
        axios.request(config).then(Result => {
                                
            console.log(Result)
            if(Result.data.error)
            {
                setFiles({...files, [file.name]:{error:"Could not upload"}});
            }
            else if(Result.data.success)
            {
                const userId = firebase.auth().currentUser.uid
                const date = Date.now();
                const filePath = `${userId}/${Result.data.id}`;
                
                const storageRef = firebase.storage().ref().child(filePath)
                storageRef.put(file).then(async (snapshot) => {
                    console.log('Uploaded a blob or file!');
                    console.log(snapshot);

                    console.log(await storageRef.getMetadata());

                    var temparray = files

                    if(file.name in files)
                    delete temparray[file.name]
                    
                    setFiles(temparray);

                    if(typeof onUpload === 'function')
                    {
                        onUpload({[Result.data.id]:{name:file.name,uploaded:true}})
                    }
                    
                })
                
            }
            else
            {
                setFiles({...files, [file.name]:{error:"Could not upload"}});
            }
          
      }).catch(err => {
            console.error("handleSubmit")
            console.error(err)

            setFiles({...files, [file.name]:{error:"Could not upload"}});      });
       
    });
    });





        // Generate a unique path using the user's ID and the current date
        

    };



    
    const handleRemove = (item) => {
        const temp = {...files}
        delete temp[item];
        setFiles(temp)



        
    };


    const handleRemoveFile = (id) =>{
        
        if(typeof onRemove === 'function')
        {
            onRemove(id)
        }
    }




    const sxTemp=sx?sx:{}

    return (
        <Box sx={sxTemp}>

            <Button onClick={()=>hiddenFileInput.current.click()} variant="contained" sx={{mt:3}}>Add file</Button>


            <input style={{display: 'none'}} ref={hiddenFileInput} type="file" onChange={handleFileChange} />
            <Box sx={{mt:1}}>

            

            
            {value && Object.keys(value).map((Row) =>  (
                    <Box sx={{display:"flex"}}>
                        <Box  sx={{flexGrow:1,textOverflow:"ellipsis",overflow: "hidden;",whiteSpace: "nowrap;",mt:"10px"}}>
                            {value[Row]}
                        </Box>

                        <IconButton onClick={()=>handleRemoveFile(Row)}><DeleteIcon/></IconButton>

                        

                    </Box>
                ))}



                
{Object.keys(files).map((Row) =>  (
                    <Box sx={{display:"flex"}}>
                        <Box  sx={{flexGrow:1,textOverflow:"ellipsis",overflow: "hidden;",whiteSpace: "nowrap;",mt:"10px"}}>
                            {Row}
                        </Box>

                        {files[Row].uploading?<Box sx={{color:"#ff9800 !important",mt:"10px"}}>Uploading</Box>:null}
                        
                        {files[Row].uploaded?<Box sx={{color:"#2d8f0d !important",mt:"10px"}}>Uploaded</Box>:null}

                        {files[Row].error?<Box sx={{color:"red !important",mt:"10px"}}>files[Row].error</Box>:null}
                        

                        {files[Row].uploaded?<IconButton onClick={()=>handleRemove(Row)}><DeleteIcon/></IconButton>:null}

                        

                    </Box>
                ))}
            </Box>
        </Box>
    );
}

export default FileUpload;
