import React from 'react'

import Box from '@mui/material/Box';

const View = () => {
    
    return (
        <Box className="Page404">
            <h3><strong>404.</strong> Could not find page.</h3>
        </Box>
    )
}
export default View
