import React from 'react'
import { NavLink,Link } from 'react-router-dom'

import { useNavigate } from 'react-router-dom';

import Box from '@mui/material/Box';
import { Container } from '@mui/material';

import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Alert from '@mui/material/Alert';

import Dialog from '@mui/material/Dialog';

import AddIcon from '@mui/icons-material/Add';
import BackIcon  from '@mui/icons-material/ArrowBackIos';

import  axios  from 'axios'
import {baseAPIURL} from "../../store/api/Access"
import firebase from 'firebase/compat/app'
import ClearIcon from '@mui/icons-material/Clear';

const View = () => {
    const [value, setValue] = React.useState({});
    const [statsArray, setStatsArray] = React.useState([]);

    const [{loading,saving,error}, setStatus] = React.useState({loading:true});
    const navigate = useNavigate();



    const handleChange = (e) => {
        setValue({... value, [e.target.name]:e.target.value})

    }

    const handleSubmit = () => {
        if(!("name" in value) || value.name == "")
        {
            alert("Name is required")
            return
        }


        
      setStatus({saving:true})

      firebase.auth().currentUser.getIdToken().then(function(idToken) {

        var DataRx = {
            "name":value.name
        }

        if(statsArray.length>0)
        {
            DataRx["stats"] = []      
            statsArray.forEach(element => {
                DataRx["stats"].push(element)
            });
        }
        
        if(value.description)
            DataRx["description"] = value.description       


        let data = JSON.stringify(DataRx);

        let config = {
          method: 'post',
          maxBodyLength: Infinity,
          url: baseAPIURL+'activities/add',
          headers: { 
            'Authorization': idToken, 
            'Content-Type': 'application/json'
          },
          data : data
        };
        
        axios.request(config).then(Result => {
                                
            console.log(Result)
            if(Result.data.error)
            {
                setStatus({error:true});
            }
            else if(Result.data.success)
            {
                setStatus({});

                if(Result.data.id)
                {
                  navigate("/activities/"+Result.data.id)
                }
            }
            else
            {
                setStatus({error:true});
            }
          
      }).catch(err => {
            console.error("handleSubmit")
            console.error(err)
            setStatus({error:true});
      });
    });

    }

    
    const handleRemoveStat = (index) =>{
        
        var tempArray = [...statsArray]
        tempArray.splice(index, 1);
        setStatsArray(tempArray)
    }
    const handleAddStat = () =>{
        setStatsArray([...statsArray, ""])
    }

    const handleChangeStat = (index, statValue) =>{
        var tempArray = [...statsArray]
        tempArray[index] = statValue;
        setStatsArray(tempArray)

    }
    
    return (
        <Box>

            <Toolbar className='BeadcrumToolBar'>
                <IconButton  component={NavLink} to="/activities" >
                    <BackIcon/>
                </IconButton>

                <Typography   sx={{ flexGrow: 1 }}  >Activitie add</Typography>

            </Toolbar>


            <Container>
            
            
            {error ? <Alert severity="error">There was an error, try again later.</Alert>:null}
            
                
            <TextField onChange={handleChange} value={value.name} sx={{mt:3}} label="Name" name="name" variant="outlined" fullWidth />
            <TextField onChange={handleChange} value={value.description} sx={{mt:3}} label="Description" name="description"  variant="outlined" fullWidth />



            <Box>
              <h3>Status</h3>
              <p></p>
              {statsArray.map((row,index) => (
                <Box sx={{display:"flex"}}>
                    <TextField onChange={(e)=>handleChangeStat(index,e.target.value)} value={row} sx={{mt:1, flexGrow:1}}  variant="outlined" />
                    
                    <Button onClick={(e)=>handleRemoveStat(index)} sx={{mt:1}}><ClearIcon/></Button>
                </Box>
              ))}

              <Button onClick={handleAddStat} variant="contained" sx={{mt:1}}>+</Button>

            </Box>


            <Button onClick={handleSubmit} variant="contained" sx={{mt:3}}>add</Button>

            </Container>


            <Dialog open={saving}>
            <Box sx={{p:3}}>
                Saving
              </Box>
            </Dialog>

        </Box>

    )
}
export default View
