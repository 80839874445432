import React,{useState,useEffect} from 'react'
import { NavLink,Link } from 'react-router-dom'

import { useNavigate } from 'react-router-dom';

import Box from '@mui/material/Box';
import { Container } from '@mui/material';

import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Alert from '@mui/material/Alert';
import Loader from "../../components/other/Loader"

import Dialog from '@mui/material/Dialog';
import DialogContentText from '@mui/material/DialogContentText';


import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import dayjs, { Dayjs } from 'dayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateField } from '@mui/x-date-pickers/DateField';

import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';

import AddIcon from '@mui/icons-material/Add';
import BackIcon  from '@mui/icons-material/ArrowBackIos';
import DeleteIcon from '@mui/icons-material/Delete';

import  axios  from 'axios'
import {baseAPIURL} from "../../store/api/Access"
import firebase from 'firebase/compat/app'
import {  useParams } from 'react-router-dom';

import UploadFiles from "../../components/other/Upload"

const View = () => {
    
  
  const [CustomFeildsList, setCustomFeildsList] = useState([]);
  const [customFeildsStatus, setCustomFeildsStatus] = useState({loading:true});

  const [activitystatus, setActivityStatus] = useState({loading:true});
  const [listActivities, setListActivities] = useState(false);
  const [openActivities, setOpenActivities] = useState(false);
  const [activityValue, setActivityValue] = useState({});

  const [contactstatus, setContactsStatus] = useState({loading:true});
  const [listContacts, setListContacts] = useState(false);
  const [openContacts, setOpenContacts] = useState(false);
  const [contactSelected, setContactSelected] = useState([]);
  const [openDelete, setOpenDelete] = React.useState(false);

  const [value, setValue] = React.useState({});


  const [{loading,savingError,saving,saved,error}, setStatus] = React.useState({loading:true});
  const navigate = useNavigate();
  const Params = useParams();


  
  const handleSubmitDelete = () => {
            
    setStatus({saving:true})
    setOpenDelete(false)
    firebase.auth().currentUser.getIdToken().then(function(idToken) {

      var DataRx = {
          "id": "id" in Params && Params.id ? Params.id : ""
      }

      let data = JSON.stringify(DataRx);

      let config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: baseAPIURL+'log/remove',
        headers: { 
          'Authorization': idToken, 
          'Content-Type': 'application/json'
        },
        data : data
      };
      
      axios.request(config).then(Result => {
                              
          console.log(Result)
          if(Result.data.error)
          {
              setStatus({error:true});
          }
          else if(Result.data.success)
          {
              setStatus({saved:true});
              navigate("/")


          }
          else
          {
              setStatus({error:true});
          }
        
    }).catch(err => {
          console.error("handleSubmit")
          console.error(err)
          setStatus({error:true});
    });
  });

  }


  const handleFilesRemove = (id) => {

    
    var temp = {... value}
    if(!("files" in temp))
    {
      return
    }

    delete temp.files[id]

    setValue(temp)

  }


  const handleFilesUpload = (files=[]) => {
    
    var temp = {... value}
    //console.log(temp)
    if(!("files" in temp))
    {
      temp.files = {}
    }

    Object.keys(files).forEach(element => {
      temp.files[element] = files[element].name
      
    });

    setValue(temp)
    

  }


  const handleActivityValueChange = (value,id,name) => {
      
      var temp = {...activityValue}

      if(!(id in temp))
      {
          temp[id] = {}
      }
      temp[id][name] = value

      setActivityValue(temp)         
  }

  const handleAddActivity = (id) => {
      setOpenActivities(false)
      var temp = {...activityValue}

      if(!(id in temp))
      {
          temp[id] = {}
          setActivityValue(temp)  
      }

  }

  
  const handleRemoveActivity = (id) => {
    setOpenActivities(false)
    var temp = {...activityValue}

    if((id in temp))
    {
        delete temp[id]
        setActivityValue(temp)  
    }
  }





    
    const handleAddContact = (item) => {
        setOpenContacts(false)

        if((item in contactSelected))
        {
          return
        }

        
        var listTemp = [...contactSelected]
        listTemp.push(item)
        //console.log(listTemp)
        setContactSelected(listTemp)

    }

    
    const handleRemoveContact = (id) => {
        var index = -1

        contactSelected.forEach((element,elementIndex) => {
            if(element == id)
                index = elementIndex
        });

        if(index==-1)
            return

        var listTemp = [...contactSelected]
        listTemp.splice(index, 1); // 2nd parameter means remove one item only
        setContactSelected(listTemp)
    }


    const handleChangeDate = (dateValue) => {
      //console.log(dateValue)
      setValue({... value, date:dateValue})
  }

    const handleChange = (e) => {
        setValue({... value, [e.target.name]:e.target.value})
    }

    const handleCustomFeildChange = (row,feildvalue) => {
      var temp = {... value}
      //console.log(temp)
      if(!("meta" in temp))
      {
        temp.meta = {}
      }

      console.log(temp)
      temp.meta[row] = feildvalue

      setValue(temp)
    }


    const handleSubmit = () => {



        /*
        req.body.date
        req.body.name
        req.body.notes
        req.body.location
        req.body.meta[key]
        req.body.activities[element][key]
*/


        if(!("name" in value) || value.name == "" || !("date" in value) || value.date == "")
        {
            alert("Name and date is required")
            return
        }



        

        
      setStatus({saving:true})

      firebase.auth().currentUser.getIdToken().then(function(idToken) {

        var DataRx = {
            "name":value.name,
            "date":value.date.format('YYYY-MM-DD'),
            "id": "id" in Params && Params.id ? Params.id : ""
        }

        if(value.notes)
            DataRx["notes"] = value.notes

        if(value.location)
            DataRx["location"] = value.location

            
        DataRx.meta = value.meta ? value.meta :{};
        DataRx.activities = {...activityValue};

        DataRx.contacts = [...contactSelected];
        DataRx.files = value.files?Object.keys(value.files):[];
        


        
        let data = JSON.stringify(DataRx);

        let config = {
          method: 'post',
          maxBodyLength: Infinity,
          url: baseAPIURL+'log/update',
          headers: { 
            'Authorization': idToken, 
            'Content-Type': 'application/json'
          },
          data : data
        };
        
        axios.request(config).then(Result => {
                                
            console.log(Result)
            if(Result.data.error)
            {
                setStatus({savingError:true});
            }
            else if(Result.data.success)
            {
                setStatus({saved:true});

                if(Result.data.id)
                {
                  navigate("/log/"+Result.data.id)
                }
            }
            else
            {
                setStatus({savingError:true});
            }
          
      }).catch(err => {
            console.error("handleSubmit")
            console.error(err)
            setStatus({savingError:true});
      });
    });

    }












    

    const LoadData = (activities = null) => {
        setStatus({loading:true});
        console.log("LoadData")

        console.log(firebase.auth().currentUser)
        firebase.auth().currentUser.getIdToken().then(function(idToken) {



          
          let data23 = JSON.stringify({
            "id": "id" in Params && Params.id ? Params.id : null
          });

          let config23 = {
            method: 'post',
            maxBodyLength: Infinity,
            url: baseAPIURL+'log/get',
            headers: { 
              'Authorization': idToken, 
              'Content-Type': 'application/json'
            },
            data : data23
          };
          
          axios.request(config23).then(Result => {
                                  
                console.log(Result)
                if(Result.data.error)
                {
                    setStatus({error:true});
                }
                else if(Result.data)
                {
                  var temp ={...Result.data}
                  const date = new Date(temp.date);

                  temp.date = dayjs(date)
                    setValue(temp)

                  if(Result.data.activities)
                    setActivityValue(Result.data.activities)

                  if(Result.data.contacts)
                    setContactSelected(Result.data.contacts)

                    setStatus({});
                }
                else
                {
                    setValue(null)
                    setStatus({error:true});
                }
            
        }).catch(err => {
              console.error("handleSubmit")
              console.error(err)
              setStatus({error:true});
        });



          
          
          let config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: baseAPIURL+'profile',
            headers: { 
              'Authorization': idToken, 
              'Content-Type': 'application/json'
            },
            data : "{}"
          };
          
          axios.request(config).then(Result => {
                                  
                console.log(Result)
                if(Result.data.error)
                {
                  setCustomFeildsStatus({error:true});
                }
                else if(Result.data)
                {
                    setCustomFeildsList(Result.data.meta)
                    setCustomFeildsStatus({});
                }
                else
                {
                  setCustomFeildsList(null)
                    setCustomFeildsStatus({error:true});
                }
            
        }).catch(err => {
              console.error("handleSubmit")
              console.error(err)
              setCustomFeildsStatus({error:true});
        });




          let datafilter = JSON.stringify({
            "limit": 100000
          });

          let configfilter = {
            method: 'post',
            maxBodyLength: Infinity,
            url: baseAPIURL+'activities/list',
            headers: { 
              'Authorization': idToken, 
              'Content-Type': 'application/json'
            },
            data : datafilter
          };
          
          axios.request(configfilter).then(Result => {
                                  
              console.log(Result)
              if(Result.data.error)
              {
                setActivityStatus({error:true});
              }
              else if(Result.data.list)
              {
                  setListActivities(Result.data.list)
                  setActivityStatus({});
              }
              else
              {
                  setListActivities([])
                  setActivityStatus({error:true});
              }
            
        }).catch(err => {
              console.error("handleSubmit")
              console.error(err)
              setActivityStatus({error:true});
        });





        
          

        let dataContacts = JSON.stringify({
            "limit": 100000
          });

          let configContacts = {
            method: 'post',
            maxBodyLength: Infinity,
            url: baseAPIURL+'contacts/list',
            headers: { 
              'Authorization': idToken, 
              'Content-Type': 'application/json'
            },
            data : dataContacts
          };
          
          axios.request(configContacts).then(Result => {
                                  
              console.log(Result)
              if(Result.data.error)
              {
                setContactsStatus({error:true});
              }
              else if(Result.data.list)
              {
                setListContacts(Result.data.list)
                  setContactsStatus({});
              }
              else
              {
                setListContacts([])
                  setContactsStatus({error:true});
              }
            
        }).catch(err => {
              console.error("handleSubmit")
              console.error(err)
              setContactsStatus({error:true});
        });


      });
        
    };

  useEffect(() => {
    LoadData();
  }, []);



  if(customFeildsStatus.loading || loading)
  {
    return(<Loader/>)
  }

    
    return (
        <Box>

            <Toolbar className='BeadcrumToolBar'>
                <IconButton  component={NavLink} to="/" >
                    <BackIcon/>
                </IconButton>

                <Typography   sx={{ flexGrow: 1 }}  >Log add</Typography>

            </Toolbar>


            <Container>
            
            
            {savingError ? <Alert severity="error">There was an error, try again later.</Alert>:null}
            {saved ? <Alert severity="success">Saved</Alert>:null}

                
            <TextField onChange={handleChange} value={value.name} sx={{mt:3}} label="Name" name="name" variant="outlined" fullWidth />


            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DateField
               sx={{mt:3}}
                label="Date"
                value={value.date}
                 variant="outlined" 
                 fullWidth
                onChange={handleChangeDate}
                format="DD-MM-YYYY"
              />
            </LocalizationProvider>

            
            <TextField onChange={handleChange} value={value.location} sx={{mt:3}} label="Location" name="location"  variant="outlined" fullWidth />


            {CustomFeildsList && CustomFeildsList.map((Row) =>  (
              <TextField onChange={(e)=>handleCustomFeildChange(Row,e.target.value)} value={value.meta && Row in value.meta ? value.meta[Row]:""} sx={{mt:3}} label={Row} name={"CF-"+Row}  variant="outlined" fullWidth />
            ))}



            <br/>
            <Box sx={{p:2,mt:3, border:"2px #002e01 solid !important"}}>

            <h2>Contacts</h2>
            <hr/>

            
    {contactstatus.loading ?<Box>Loading</Box>:null}
            {listContacts && listContacts.map((contactRow) => {
              if(!(contactSelected.includes(contactRow.id) ))
              {
                return null
              }


              return (
                    <Box sx={{display:"flex"}}>
                    <h3 style={{flexGrow:1}}>{contactRow.name}</h3>
                    <IconButton onClick={()=>handleRemoveContact(contactRow.id)}><DeleteIcon/></IconButton>
                    </Box>

            )})}

            <Button onClick={()=>setOpenContacts(true)} variant="contained" sx={{mt:3}}>Add</Button>

            
            </Box>

            <br/>
            <Box sx={{p:2,mt:3, border:"2px #002e01 solid !important"}}>

            <h2>Activities</h2>
            <hr/>
    {activitystatus.loading ?<Box>Loading</Box>:null}
            {listActivities && listActivities.map((activityRow) => {
              
              if(!(activityRow.id in activityValue))
              {
                return null
              }

              return(
                <Box >
                    <Box sx={{display:"flex"}}>
                    <h3 style={{flexGrow:1}}>{activityRow.name}</h3>
                    <IconButton onClick={()=>handleRemoveActivity(activityRow.id)}><DeleteIcon/></IconButton>
                    </Box>
                    <Box>
                        {activityRow.stats && activityRow.stats.map((statsRow) => (
                            <TextField onChange={(e)=>handleActivityValueChange(e.target.value,activityRow.id,statsRow)} value={activityRow.id in activityValue && statsRow in activityValue[activityRow.id] ? activityValue[activityRow.id][statsRow] : ""} sx={{mt:3}} label={statsRow} variant="outlined" fullWidth />
                        ))}
                    </Box>

                </Box>
            )})}

            <Button onClick={()=>setOpenActivities(true)} variant="contained" sx={{mt:3}}>Add</Button>

            
            </Box>

            <TextField 
          multiline
          minRows={10}
          maxRows={20}
          onChange={handleChange} value={value.notes} sx={{mt:3}} label="Notes" name="notes"  variant="outlined" fullWidth />


          <UploadFiles onUpload={handleFilesUpload} onRemove={handleFilesRemove} value={value.files}/>



<br/>{/*
            <Box sx={{p:2,mt:3, border:"2px #002e01 solid !important"}}>

            <h2>files</h2>
            <hr/>
            {contactSelected && contactSelected.map((contactRow) => (
                    <Box sx={{display:"flex"}}>
                    <h3 style={{flexGrow:1}}>{contactRow.name}</h3>
                    <IconButton onClick={()=>handleRemoveContact(contactRow.id)}><DeleteIcon/></IconButton>
                    </Box>

            ))}

            <Button onClick={()=>setOpenContacts(true)} variant="contained" sx={{mt:3}}>Add</Button>

            
            </Box>*/}



            <br/><br/>
            <Button onClick={handleSubmit} variant="contained" sx={{mt:3}}>Save</Button>
            <br/>
            <br/>

            <Button onClick={()=>setOpenDelete(true)} variant="contained" sx={{mt:3}}>Delete</Button>

            </Container>


            <Dialog open={saving}>
            <Box sx={{p:3}}>

                Saving
                </Box>
            </Dialog>






  
            <Dialog  open={openActivities}>
              <DialogTitle>Activities</DialogTitle>
              <DialogContent>
                
                {activitystatus.loading ?<Box>Loading</Box>:
              <Box sx={{mt:3}}>
                    <hr/>
                    <Box>
                        {activitystatus && listActivities.map((row) => (
                            <Box sx={{ cursor: "pointer; ", marginBottom:"10px"}} onClick={()=>handleAddActivity(row.id)}>{row.name}</Box>
                        ))}
                    </Box></Box>}
              </DialogContent>
              <DialogActions>
                <Button onClick={()=>setOpenActivities(false)}>Cancel</Button>
              </DialogActions>
            </Dialog>




            
  
<Dialog  open={openContacts}>
  <DialogTitle>Conacts</DialogTitle>
  <DialogContent>
    
    {contactstatus.loading ?<Box>Loading</Box>:
  <Box sx={{mt:3}}>
        <hr/>
        <Box>
            {listContacts && listContacts.map((row) => {
              
              return (
                <Box sx={{ cursor: "pointer; ", marginBottom:"10px"}} onClick={()=>handleAddContact(row.id)}>{row.name}</Box>
            )})}
        </Box></Box>}
  </DialogContent>
  <DialogActions>
    <Button onClick={()=>setOpenContacts(false)}>Cancel</Button>
  </DialogActions>
</Dialog>


            
<Dialog  open={openDelete}>
              <DialogTitle>Delete</DialogTitle>
              <DialogContent>
                <DialogContentText>
                  Are you sure you want to delete?
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={()=>setOpenDelete(false)}>Cancel</Button>
                <Button onClick={handleSubmitDelete}>Delete</Button>
              </DialogActions>
            </Dialog>



            
  {/*
<Dialog  open={false}>
  <DialogTitle>File</DialogTitle>
  <DialogContent>
    
  </DialogContent>
  <DialogActions>
    <Button onClick={()=>setOpenContacts(false)}>Cancel</Button>
  </DialogActions>
</Dialog>*/}


            
        </Box>

    )
}
export default View
