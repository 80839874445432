
import React, {useEffect ,useState  } from 'react'
import { NavLink,Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { firestoreConnect } from  'react-redux-firebase'


import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';

import Page404 from '../Page404';
import Loader from "../../components/other/Loader"

import {baseAPIURL,downloadAPIURL} from "../../store/api/Access"

import  axios  from 'axios'




import AddIcon from '@mui/icons-material/Add';
import BackIcon  from '@mui/icons-material/ArrowBackIos';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import Dialog from '@mui/material/Dialog';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import FormControlLabel from '@mui/material/FormControlLabel';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import Checkbox from '@mui/material/Checkbox';

import Box from '@mui/material/Box';

import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import { useNavigate, useParams } from "react-router-dom";
import { useSearchParams } from "react-router-dom";




const View = () => {
    

  const [selected, setSelected] = React.useState([]);

  const [activitystatus, setActivityStatus] = useState({loading:true});
  const [listActivities, setListActivities] = useState(false);
  const [openFilters, setOpenFilters] = useState(false);

    const [limit, setLimit] = useState(60);
    const [list, setList] = useState(null);
    const [{loading,loadingMore,error}, setStatus] = useState({loading:true});
    let [searchParams, setSearchParams] = useSearchParams();

    const navigate = useNavigate();
    

    const HandleApplyFilter = () => {
      setSearchParams({activities:selected})
      LoadData([...selected]);
      setOpenFilters(false);
    }



      const LoadData = (activities = null) => {
          setStatus({loading:true});
          console.log("LoadData")
  
          console.log(firebase.auth().currentUser)
          firebase.auth().currentUser.getIdToken().then(function(idToken) {

            

            let datafilter = JSON.stringify({
              "limit": 100000
            });
  
            let configfilter = {
              method: 'post',
              maxBodyLength: Infinity,
              url: baseAPIURL+'activities/list',
              headers: { 
                'Authorization': idToken, 
                'Content-Type': 'application/json'
              },
              data : datafilter
            };
            
            axios.request(configfilter).then(Result => {
                                    
                console.log(Result)
                if(Result.data.error)
                {
                  setActivityStatus({error:true});
                }
                else if(Result.data.list)
                {
                    setListActivities(Result.data.list)
                    setActivityStatus({});
                    if(searchParams && searchParams.activities)
                    {
                      setSelected(searchParams.activities.split(","))
                    }
                    else if(!activities)
                    {
                      handleSelectAll()
                    }
                }
                else
                {
                    setListActivities([])
                    setActivityStatus({error:true});
                }
              
          }).catch(err => {
                console.error("handleSubmit")
                console.error(err)
                setActivityStatus({error:true});
          });




          var dataarray = {
            "limit": limit
          }

          if(searchParams && searchParams.activities)
          {
            dataarray.activities = searchParams.activities.split(",")
          }

          if(activities)
          {
            dataarray.activities = activities
          }

            let data = JSON.stringify(dataarray);

            let config = {
              method: 'post',
              maxBodyLength: Infinity,
              url: baseAPIURL+'log/list',
              headers: { 
                'Authorization': idToken, 
                'Content-Type': 'application/json'
              },
              data : data
            };
            
            axios.request(config).then(Result => {
                                    
                console.log(Result)
                if(Result.data.error)
                {
                    setStatus({error:true});
                }
                else if(Result.data.list)
                {
                    setList(Result.data.list)
                    setStatus({});
                }
                else
                {
                    setList([])
                    setStatus({error:true});
                }
              
          }).catch(err => {
                console.error("handleSubmit")
                console.error(err)
                setStatus({error:true});
          });
        });
          
      };
  
    useEffect(() => {
      LoadData();
    }, []);




    
    const handleSelectChange = (value,uuid) => {
        
      var selectedTemp = [...selected]
      if(!value)
      {
          const index = selectedTemp.indexOf(uuid);
          if (index > -1) 
          { 
              selectedTemp.splice(index, 1); 
          }
      }
      else
      {
          selectedTemp.push(uuid)
      }
      setSelected(selectedTemp)
      console.log(selectedTemp)
  }

  const handleSelectAll = () => {
      var includeStatsTemp = []
      listActivities.forEach(element => {
          includeStatsTemp.push(element.id)
      });

      setSelected(includeStatsTemp); 
  }


  const handleSelectNone = () => {
      setSelected([])
  }


    if(error)
      return(<Page404/>)

    if(loading)
      return(<Loader/>)


      return (
        <Box>
          <Toolbar className='BeadcrumToolBar'>
            
          <IconButton  onClick={()=>setOpenFilters(!openFilters)} >
                            <FilterAltIcon/>
                        </IconButton>

                        <Typography   sx={{ flexGrow: 1 }}  className='Title' variant="h6" >
                            Log
                        </Typography>
                    
                        <IconButton component={NavLink} to="/add"  onClick={()=>setOpenFilters(!openFilters)} >
                            <AddIcon/>
                        </IconButton>

          </Toolbar>
    <TableContainer >
    <Table sx={{ minWidth: 650 }} aria-label="simple table">
      <TableHead>
        <TableRow>
          <TableCell>Name</TableCell>
          <TableCell>Date</TableCell>
          <TableCell>Location</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {list && list.map((row) => (
          <TableRow
            hover
            key={row.name}
            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            component={NavLink}
            to={"/log/"+row.id}
          >
            <TableCell >{row.name}</TableCell>
            <TableCell >{row.date}</TableCell>
            <TableCell >{row.location}</TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  </TableContainer>


  
            <Dialog  open={openFilters}>
              <DialogTitle>Filter</DialogTitle>
              <DialogContent>
                
                {activitystatus.loading ?<Box>Loading</Box>:
              <Box sx={{mt:3}}>
                    <Button onClick={handleSelectAll} sx={{p:0, mr:1,fontSize:"12px"}}>All</Button>
                    <Button onClick={handleSelectNone} sx={{p:0, mr:1,fontSize:"12px"}}>None</Button>
                    <hr/>
                    <Box>
                        {activitystatus && listActivities.map((row) => (
                            <Box>
                                <FormControlLabel control={<Checkbox  checked={selected.includes(row.id)} onChange={(e)=>handleSelectChange(e.target.checked,row.id)} />} label={row.name} />
                            </Box>
                        ))}
                    </Box></Box>}
              </DialogContent>
              <DialogActions>
                <Button onClick={()=>setOpenFilters(false)}>Cancel</Button>
                <Button onClick={HandleApplyFilter}>Apply</Button>
              </DialogActions>
            </Dialog>


        </Box>
    )
}
export default View
