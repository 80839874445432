import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux'
import firebase from 'firebase/compat/app'
import {useRef} from 'react';
import { NavLink,Link } from 'react-router-dom'
import  axios  from 'axios'

import AppBar from '@mui/material/AppBar';
import {Box,Badge} from '@mui/material';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Drawer from '@mui/material/Drawer';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import AddIcon from '@mui/icons-material/Add';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import NotificationsIcon from '@mui/icons-material/Notifications';
import MenuIcon from '@mui/icons-material/Menu';


import Button from '@mui/material/Button';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import HomeIcon from '@mui/icons-material/Home';
import SettingsIcon from '@mui/icons-material/Settings';
import PermMediaIcon from '@mui/icons-material/PermMedia';
import LeaderboardIcon from '@mui/icons-material/Leaderboard';
import DirectionsRunIcon from '@mui/icons-material/DirectionsRun';
import PeopleIcon from '@mui/icons-material/People';
import ListIcon from '@mui/icons-material/List';

const Header = ({user}) => {    
    
    const [menuActice, setMenuActice] = useState(false);

    
    const handleClick = () => {
            setMenuActice(!menuActice)
    };

    const handleClose = () => {
        setMenuActice(false)
    };



    return (
        <Box >
            <AppBar position="static">
                <Toolbar className='Header'>
                    
                    
                        <IconButton  onClick={handleClick} >
                            <MenuIcon/>
                        </IconButton>

                        <Typography   sx={{ flexGrow: 1, textAlign:"center" }} component={NavLink} to="/" className='Title' variant="h6" >
                            Advlog
                        </Typography>
                    

                        <Box/>

{/*
                        <IconButton  component={NavLink} to="/add" >
                            <AddIcon/>
                        </IconButton>
    */}
                        <Drawer
                            anchor="left"
                            open={menuActice}
                            onClose={handleClose}
                        >
                            
                            <Box
                                sx={{ maxWidth:250,width:"80vw" }}
                                role="presentation"
                                onClick={handleClose}
                                onKeyDown={handleClose}
                            >
                                
                                <List>
                                    <ListItem key="Log" disablePadding>
                                        <ListItemButton component={NavLink} to="/">
                                        <ListItemIcon>
                                            <ListIcon />
                                        </ListItemIcon>
                                        <ListItemText primary="Log" />
                                        </ListItemButton>
                                    </ListItem>
                                    <ListItem key="Stats" disablePadding>
                                        <ListItemButton component={NavLink} to="/stats">
                                        <ListItemIcon>
                                            <LeaderboardIcon />
                                        </ListItemIcon>
                                        <ListItemText primary="Stats" />
                                        </ListItemButton>
                                    </ListItem>
                                    <ListItem key="Make PDF" disablePadding>
                                        <ListItemButton component={NavLink} to="/pdf">
                                        <ListItemIcon>
                                            <PictureAsPdfIcon />
                                        </ListItemIcon>
                                        <ListItemText primary="Make PDF" />
                                        </ListItemButton>
                                    </ListItem>
                                    <ListItem key="Files" disablePadding>
                                        <ListItemButton component={NavLink} to="/files">
                                        <ListItemIcon>
                                            <PermMediaIcon />
                                        </ListItemIcon>
                                        <ListItemText primary="Files" />
                                        </ListItemButton>
                                    </ListItem>
                                    
                                    <ListItem key="Activities" disablePadding>
                                        <ListItemButton component={NavLink} to="/activities">
                                        <ListItemIcon>
                                            <DirectionsRunIcon />
                                        </ListItemIcon>
                                        <ListItemText primary="Activities" />
                                        </ListItemButton>
                                    </ListItem>
                                    <ListItem key="Contacts" disablePadding>
                                        <ListItemButton component={NavLink} to="/contacts">
                                        <ListItemIcon>
                                            <PeopleIcon />
                                        </ListItemIcon>
                                        <ListItemText primary="Contacts" />
                                        </ListItemButton>
                                    </ListItem>
                                    <ListItem key="settings" disablePadding>
                                        <ListItemButton component={NavLink} to="/settings">
                                        <ListItemIcon>
                                            <SettingsIcon />
                                        </ListItemIcon>
                                        <ListItemText primary="settings" />
                                        </ListItemButton>
                                    </ListItem>
                                </List>
                                <Box  sx={{pl:2,pr:2}}>
                                    <Button fullWidth variant="contained" component={NavLink} to="/add" >Logout</Button>

                                </Box>
                            </Box>
                        </Drawer>
{/*}
                        <Menu
                            id="basic-menu"
                            anchorEl={menuActice}
                            open={(Boolean(menuActice) && isWideView>500)}
                            onClose={handleClose}
                        >
                            <MenuItem component={NavLink} to="/" onClick={handleClose}>Home</MenuItem>
                            <MenuItem component={NavLink} to="/files" onClick={handleClose}>Files</MenuItem>
                            <MenuItem component={NavLink} to="/settings" onClick={handleClose}>Settings</MenuItem>
                            <MenuItem component={NavLink} to="/logout" onClick={handleClose}>Logout</MenuItem>
                        </Menu>
    */}
                </Toolbar>
            </AppBar>
              {/*}  
            {isWideView<=500 && Boolean(menuActice) ?
                <MenuList dense sx={{background:"#fff", topBoarder:"solid #000 1px"}}>
                    <MenuItem component={NavLink} to="/" onClick={handleClose}><ListItemText>Home</ListItemText></MenuItem>
                    <MenuItem component={NavLink} to="/files" onClick={handleClose}><ListItemText>Files</ListItemText></MenuItem>
                    <MenuItem component={NavLink} to="/settings" onClick={handleClose}><ListItemText>Settings</ListItemText></MenuItem>
                    <MenuItem component={NavLink} to="/logout" onClick={handleClose}><ListItemText>Logout</ListItemText></MenuItem>
                </MenuList>

                :null
            }*/}
        </Box>
    );
}


export default (Header)